import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import useToggleState from "../hooks/useToggleState";
import MobileMenu from "./mobileMenu";

const Nav = () => {
  const [menuOpen, toggleMenu] = useToggleState(false);

  return (
    <>
      <MobileMenu menuOpen={menuOpen} toggleMenu={toggleMenu} />

      <nav className={`nav ${menuOpen ? 'nav--open' : ''}`}>
        <ul className="nav__links">
          <li>
            <div
              role="link"
              className="nav__link"
              onClick={() => {
                scrollTo("#action");
                toggleMenu();
              }}
              onKeyPress={() => {
                scrollTo("#action")
                toggleMenu();
              }}
              tabIndex={-1}
            >
             <p className="nav__link__text"> Gun Violence Numbers</p>
            </div>
          </li>
          <li>
            <div
              role="link"
              className="nav__link"
              onClick={() => {
                scrollTo("#reform");
                toggleMenu();
              }}
              onKeyPress={() => {
                scrollTo("#reform")
                toggleMenu();
              }}
              tabIndex={-1}
            >
             <p className="nav__link__text">Change We Need</p>
            </div>
          </li>
          <li>
            <div
              role="link"
              className="nav__link"
              onClick={() => {
                scrollTo("#social-share");
                toggleMenu();
              }}
              onKeyPress={() => {
                scrollTo("#social-share")
                toggleMenu();
              }}
              tabIndex={-1}
            >
              <p className="nav__link__text">Demand Action Now</p>
            </div>
          </li>
          <li>
            <div
              role="link"
              className="nav__link"
              onClick={() => {
                scrollTo("#invest");
                toggleMenu();
              }}
              onKeyPress={() => {
                scrollTo("#invest")
                toggleMenu();
              }}
              tabIndex={-1}
            >
              <p className="nav__link__text">Donate</p>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Nav;
