/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"

 function Seo({ lang, meta, title }) {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
           }
         }
       }
     `
   )
   const titleText = `Tracking Gun Violence Losses Under Biden`
   const descriptionText = `As gun deaths and injuries reach crisis levels across the country and congressional Republicans continue to block lifesaving legislation, President Joe Biden must lead by doing everything and anything he can to prioritize gun violence reduction.`
   const metaDescription = descriptionText || site.siteMetadata.description
   const defaultTitle = site.siteMetadata.title

   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       title={defaultTitle}
       titleTemplate={`${defaultTitle} — ${metaDescription}`}
       meta={[
         {
           name: `description`,
           content: metaDescription,
         },
         {
           property: `og:title`,
           content: titleText,
         },
         {
           property: `og:description`,
           content: metaDescription,
         },
         {
           property: `og:type`,
           content: `website`,
         },
          {
            property: `og:image`,
            content:`https://www.shockmarket.org/tweetDefaultImg.png`
          },
  
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:image`,
            content:`https://www.shockmarket.org/tweetDefaultImg.png`
          },
         {
           name: `twitter:creator`,
           content: site.siteMetadata.author || ``,
         },
         {
           name: `twitter:title`,
           content: titleText,
         },
         {
           name: `twitter:description`,
           content: metaDescription,
         },
         {
           name: 'google-site-verification',
           content: '4fVeUpA4FD7rz4NB0fS1U01WFysZBAXF7C_outxgBpI'
         },
       ].concat(meta)}
       >
         {/*  Meta Pixel Code */}
      <script>{`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function()

            {n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}
            ;
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '242320864749232');
            fbq('track', 'PageView');
        `}</script>
         <noscript>{`
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=242320864749232&ev=PageView&noscript=1"
          />
        `}</noscript>

        {/*  End of Meta Pixel Code */}
       </Helmet>

   )
 }

 Seo.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
 }

 Seo.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string,
 }

 export default Seo
