import React from "react";

const CloseBtn = ({ handleClose }) => {
  return (
    <button 
      className="close__x-wrap"
      onKeyPress={handleClose}
      onClick={handleClose}
      tabIndex={0}>
        <div className="close__x" />
        <div className="close__x" />
    </button>
  );
};

export default CloseBtn;