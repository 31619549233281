import React from "react";
import ReactMarkdown from "react-markdown";
import useToggleState from "./../hooks/useToggleState";
import VideoOverlay from "./../components/videoOverlay";
import { Dialog } from "@reach/dialog";
import { GoTriangleDown } from "react-icons/go";
import { GrPlayFill } from "react-icons/gr";
import { IconContext } from "react-icons";
// import EddieVedder from "./../images/eddie-vedder-portrait.jpg";
// import EddieVedderBw from "./../images/eddie-vedder-portrait-bw.jpg";
import Manny from "./../images/newManny.png";
//import MannyBw from "./../images/manny-portrait-bw.jpg";
import scrollTo from "gatsby-plugin-smoothscroll";
// import Frame from "./../images/svg/watch-frame.inline.svg";

const Hero = ({ copy, video }) => {
  const [overlayOpen, toggle] = useToggleState(false);
  const handleClick = () => toggle();

  return (
    <>
    <section className="hero">
      <div className="hero__bkd"></div>
      <div className="hero__shock-market">
        {/* <ShockMarket /> */}
      </div>
      <div className="hero__content">
        <ReactMarkdown children={copy} className="section-intro hero__intro" />

        <div 
          className="hero__vid-wrap" 
          role="button" 
          tabIndex="-1" 
          onClick={() => handleClick()} 
          onKeyPress={() => handleClick()}>
          <div className="hero__vid-poster">
            <img
              src={`${Manny}`}
              className="hero__vid-poster-color"
              alt="shock market sign"
            />
            <img
              src={`${Manny}`}
              className="hero__vid-poster-bw"
              alt="shock market sign"
            />
            <IconContext.Provider value={{ className: "hero__play" }}>
              <GrPlayFill />
            </IconContext.Provider>
          </div>
          <div className="hero__vid-watch"><span>Watch Video</span></div>
        </div>

  
        <div
          className="hero__scroll"
          role="link"
          tabIndex="-1"
          onClick={() => scrollTo("#action")}
          onKeyPress={() => scrollTo("#action")}
        >
          scroll down
          <IconContext.Provider value={{ className: "hero__down" }}>
            <GoTriangleDown />
          </IconContext.Provider>
         
        </div>
      </div>
    </section>

    <Dialog isOpen={overlayOpen} aria-label="overlay">
        <VideoOverlay handleClose={handleClick} video={video}/>   
    </Dialog>
    </>
  );
};

export default Hero;
