import React, { useEffect } from "react";
import { useState, useRef } from "react";
import Select from "react-select";
import getRandomInt from "../helpers/getRandomInt";
import makeHandle from "../helpers/makeHandle";
import { selectStyles } from "./selectStyles";


const ShareTweet = ({
  activeCategory,
  activeStat,
  activeRecipient,
  recipients,
  defaultMessage,
  messages,
  handleRecipientChange,
  lastUpdated
}) => {


  const lastUpdatedFormatted = lastUpdated.split(' ')[0].replaceAll('/', '-');

  const twitterHandles = recipients.map(recipient => ({
    value: recipient.node.twitterHandle,
    label: recipient.node.name
  }));

  const retrieveMessage = () => {
    const randomInt = getRandomInt(0, messages.length - 1);
    return messages[randomInt].message;
  };

  // needed for controlled form input
  const [fullTweetValue, setFullTweetValue] = useState(
    `${activeRecipient.twitterHandle}, ${defaultMessage}`
  );

  const textArea = useRef();

  useEffect(
    () => {
      const autoHeight = (element) => {
        element.style.height='auto';
        element.style.height=element.scrollHeight+5+'px';
      }

      autoHeight(textArea.current);
    }
  )

  return (
    <div className="share__form-wrap">

      <label htmlFor="recipient-select" className="share__form-label">Select Recipient:</label>
      <Select
        name="recipient-select"
        className="share__form-select"
        styles={selectStyles}
        options={twitterHandles}
        isSearchable={false}
        defaultValue={twitterHandles[0]}
        value={{label: activeRecipient.name}}
        onChange={event => {
          handleRecipientChange({twitterHandle: event.value, name: event.label}); // update activeRecipient state
          setFullTweetValue(`${event.value}, ${retrieveMessage()}`);
        }}
      />
      <label className="share__form-label" htmlFor="tweet-message">Tweet Message:</label>
      <textarea
        className="share__form-text"
        id="tweet-message"
        ref={textArea}
        // rows="5"
        maxLength="257"
        value={fullTweetValue}
        onChange={event => setFullTweetValue(event.target.value)}
      />
      <a
        href={`https://twitter.com/intent/tweet?&text=${fullTweetValue.replaceAll(" ", "%20")}&url=https://www.shockmarket.org/${makeHandle(activeCategory)}/${makeHandle(activeStat.stat_name)}-${lastUpdatedFormatted}`}
        className="btn btn--share"
        target="_blank"
        rel="noreferrer"
        aria-label="Share on Twitter"
      >
        Share
      </a>
    </div>
  );
};

export default ShareTweet;
