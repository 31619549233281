import React from "react";
import StatsContainer from "./statsContainer";


const SocialShare = ({headline, socialShareCopy, recipients, tweetMessages, stats, emailMessages, lastUpdated}) => {

  return (
    <section id="social-share" className="social gradient-bkd">
      <div className="container">
        <StatsContainer
          headline={headline}
          stats={stats}
          socialShareCopy={socialShareCopy}
          recipients={recipients}
          tweetMessages={tweetMessages}
          emailMessages={emailMessages}
          containerType="gallery"
          lastUpdated={lastUpdated}
        />
      </div>
    </section>
  )
};

export default SocialShare;