import React from "react";
import Nav from "./nav"


const Header = () => {
  return (
    <header className="header container">
      <Nav />
    </header>
  )
};

export default Header;