import React from "react";
import { useState } from "react";
import "@reach/dialog/styles.css";
import { IconContext } from "react-icons";
import { FaShare } from "react-icons/fa";
import { BsTriangleFill } from "react-icons/bs";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import makeHandle from "../helpers/makeHandle"

const TabPanelContent = ({
  stats,
  category,
  handleStatSelection,
  toggleOverlay,
  counted
}) => {

  const [statsVisible, setStatsVisible] = useState(false);
  const validStats = stats.filter(stat => stat.stat_number > 0);

  const triggerImageBuild = function(stat, category){
    fetch(`https://api.shockmarket.org/screenshot/twitter-cards/${makeHandle(category)}/${makeHandle(stat.stat_name)}`)
        .then(function(){
          handleStatSelection(stat, category);
          toggleOverlay();
        })
    }

  return (
    <VisibilitySensor
    onChange={isVisible => isVisible ? setStatsVisible(true) : '' }
    delayedCall
    partialVisibility
    >
    <ul className="stats__list">
      {validStats.map((stat, i) => (
        <li
          key={`stat_name-${i}`}
          className="stats__stat"
          tabIndex={-1}
          role="button"
          onClick={() => {
            triggerImageBuild(stat, category);
          }}
          onKeyPress={() => {
            triggerImageBuild(stat, category);
          }}
        >

          <div className="stats__stat-name">
            {stat.display_stat_name}
          </div>

          {category !== 'under_biden' && (
            <IconContext.Provider value={{ className: "stats__up-icon" }}>
              <BsTriangleFill />
            </IconContext.Provider>
          )}

          {!counted &&
            // show countup if hasn't been seen before, show static number if has
            <div className="stats__stat-number ">
              {stat.stat_number !== 0 &&
                statsVisible &&
                <CountUp
                  prefix={category !== 'under_biden' ? '+' : ''}
                  start={1}
                  end={stat.stat_number}
                  duration={0.5}
                  delay={0}
                  separator=','
                >
                  {({ countUpRef }) => <div ref={countUpRef} />}
                </CountUp>
              }
            </div>
          }

          {counted &&
            <div className="stats__stat-number">
              {category !== 'under_biden' ? '+' : ''}{stat.stat_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          }

          <div id="stats" className="stats__share-wrap">
            <button className="stats__share-btn-wrap">
              <IconContext.Provider
                value={{ className: "stats__share-icon" }}
              >
                <FaShare />
              </IconContext.Provider>

              <span className="stats__share">tweet</span>
            </button>
          </div>
        </li>
      ))}
  </ul>
  </VisibilitySensor>
  );
};

export default TabPanelContent;
