import React from "react";

const MobileMenu = ({ menuOpen, toggleMenu }) => {
  return (
    <div
      className="mobile-menu"
      role="button"
      tabIndex={-1}
      onClick={() => toggleMenu()}
      onKeyPress={() => toggleMenu()}
    >
      <div className={`bar bar--top ${menuOpen ? "bar--open" : ""} `}/>
      <div className={`bar bar--middle ${menuOpen ? "bar--open" : ""} `}/>
      <div className={` bar bar--bottom ${menuOpen ? "bar--open" : ""}`}/>
    </div>
  );
};

export default MobileMenu;