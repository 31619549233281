import React from "react";
import ReactMarkdown from "react-markdown";
import StatsContainer from "./statsContainer";

const Action = ({
  headline,
  copy,
  lastUpdated,
  recipients,
  stats,
  tweetMessages,
  legalCopy,
  scrollTo
}) => {
 
  return (
    <section id="action" className="action gradient-bkd">
      <div className="container">
        <h2 className="section-header">
          {headline}
        </h2>
        <ReactMarkdown linkTarget="_blank" children={copy} className="section-intro section-intro--action"/>
        <StatsContainer
          containerType="tab-list"
          stats={stats}
          lastUpdated={lastUpdated}
          recipients={recipients}
          tweetMessages={tweetMessages}
        />
        <div className="legal">
          <ReactMarkdown linkTarget="_blank" children={legalCopy} />
          <button onClick={() => scrollTo('#methodology')}>methodology</button> 
        </div>
      </div>
    </section>
  );
};

export default Action;