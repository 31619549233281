import React from "react"
import yesterdayFormatted from "../helpers/yesterdayFormatted"
import makeHandle from "../helpers/makeHandle"

const StatShareGraphic = ({ category, stat, dateInfo, lazy }) => {

  let category_title;

  if (category === 'yesterday') {
    category_title = `on ${yesterdayFormatted}`
  } else if (category === 'past_seven_days') {
    category_title = "in the Past Seven Days"
  } else if (category === 'under_biden') {
    category_title = "Under Biden"
  }

  const categoryHandle = makeHandle(category);
  const statNameHandle = makeHandle(stat.stat_name);

  //formatted
  const dateString = JSON.stringify(`${dateInfo}`);
  dateString.split(" ", 1).join("").replaceAll('/', '-')
  const year = dateString.slice(7, 11);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(1,3);
  const formattedDate = `${year}-${day}-${month}`;

  const twitterCardImage = `https://shock-market-api.s3.amazonaws.com/twitter-cards-${categoryHandle}-${statNameHandle}${formattedDate}.jpeg`

  if ( lazy ){
    return <img className="stat-graphic__img stat-graphic__tall" src={twitterCardImage} alt=" " />
  } else {
    return <img className="stat-graphic__img" src={twitterCardImage} />
  }

}

export default StatShareGraphic;
