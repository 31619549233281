export const selectStyles = {
  // container: (provided) => ({
  //   ...provided,
  //   width: '150px',
  //   marginLeft: '10px'
  // }),
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    borderWidth: '2px',
    borderColor: '#4d4d4d',
    background: '#000000',
    cursor: 'pointer',
    padding: '.5rem'
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '0',
    border: '2px solid #4d4d4d',
    color: '#ffffff',
    background: '#000000'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#ffffff'
  }),
  option: (provided, state) => {
    let background = 'transparent'
    if (state.isFocused) {
      background = '#00c1c6'
    }
    if (state.isSelected) {
      background = '#00c1c6'
    }
    return {
      ...provided,
      background: background,
      cursor: 'pointer'
    }
  },
  indicatorSeparator: () => ({
    display: 'none'
  })
}