import React from "react";
import { BsTriangleFill } from "react-icons/bs";
import { IconContext } from "react-icons";

const Ticker = ({ stats }) => {

  return (
    <section className="ticker">
      <div className="ticker__stats">
        {stats.map((stat, i) =>
          <span key={i} className="ticker__stat">
            {stat.state_name}
            <IconContext.Provider value={{ className: "ticker__up-icon" }}>
              <BsTriangleFill />
            </IconContext.Provider>
            <span className="ticker__plus">+</span>
            {stat.stat_number}
          </span>
        )}
        <div className="ticker__shadow">
          {stats.map((stat, i) =>
            <span key={i} className="ticker__stat">
              {stat.state_name}
              <IconContext.Provider value={{ className: "ticker__up-icon" }}>
                <BsTriangleFill />
              </IconContext.Provider>
              <span className="ticker__plus">+</span>
              {stat.stat_number}
            </span>
          )}
        </div>
      </div>
      <div className="container ticker__title-wrap">
        <h3 className="ticker__title">SHOOTINGS IN THE PAST 72 HRS</h3>
      </div>
    </section>
  );
};

export default Ticker;