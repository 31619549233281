import React from "react"
import CloseBtn from "./closeBtn";
import YouTube from 'react-youtube';

const VideoOverlay = ({ handleClose, video }) => {

  return (
    <>
      <div className="vid-overlay" 
        onClick={handleClose}
        onKeyPress={handleClose}
        role="button"
        tabIndex={0}
      > 
      
        <div className="close__wrap">
          <CloseBtn handleClose={handleClose}/>
        </div>
      
        <div class="vid-overlay__vid-centering-wrap">
          <div className="vid-overlay__vid-wrap">
            {video && (
              <div className="vid-overlay__vid">
                <YouTube
                  className="vid-overlay__iframe" 
                  videoId={video}
                  onEnd={() => handleClose()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default VideoOverlay