import { graphql } from "gatsby";
import * as React from "react";
import { useState } from "react";
import Layout from "../components/layout";
import Action from "../components/action";
import Invest from "../components/invest";
import Hero from "../components/hero";
import Reform from "../components/reform";
import SocialShare from "../components/socialShare";
import Ticker from "../components/ticker";
import VisibilitySensor from "react-visibility-sensor";
import ToTop from "../components/toTop";
import SeoGeneral from "../components/seoGeneral";
import Legal from "../components/legal";
import Methodology from "../components/methodology"
import Iframe from "../components/iframe";
import scrollTo from 'gatsby-plugin-smoothscroll';
import CookieConsent from "react-cookie-consent";

// markup
const IndexPage = ({ data }) => {
  const [toTop, showToTop] = useState(false);

  return (
    <Layout>
      <SeoGeneral />
      <Hero
        headline={data.strapiIntro.headline}
        copy={data.strapiIntro.description}
        video={data.strapiIntro.videoID}
      />

      <Action
        headline={data.strapiAction.headline}
        copy={data.strapiAction.copy}
        recipients={data.allStrapiRecipients.edges}
        lastUpdated = {data.allOverallStats.nodes[0].data_last_updated_at}
        stats={data.allOverallStats.nodes[0]}
        tweetMessages={data.allStrapiTweetMessages.edges}
        legalCopy={data.strapiAction.LegalText}
        scrollTo={scrollTo}
      />

      <VisibilitySensor
        onChange={isVisible => (isVisible ? showToTop(true) : showToTop(false))}
        delayedCall
        partialVisibility
      >
        <div>
          <Reform
            headline={data.strapiReform.headline}
            copy={data.strapiReform.copy}
            actionItems={data.strapiReform.actionItem}
          />

          <Ticker stats={data.allOverallStats.nodes[0].latest_shootings} />

          <SocialShare
            stats={data.allOverallStats.nodes[0]}
            headline={data.strapiSocialShare.headline}
            socialShareCopy={data.strapiSocialShare.copy}
            recipients={data.allStrapiRecipients.edges}
            tweetMessages={data.allStrapiTweetMessages.edges}
            lastUpdated = {data.allOverallStats.nodes[0].data_last_updated_at}
          />

          <Invest
            headline={data.strapiInvest.headline}
            copy={data.strapiInvest.copy}
            items={data.strapiInvest.investItem}
          />

          <Iframe />
          <Methodology scrollTo={scrollTo} copy={data.strapiMethodology.methodologyText}/>
          <Legal 
            copy={data.strapiLegal.legal_copy}
          />
        </div>
      </VisibilitySensor>
      {toTop && <ToTop />}
      <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="myAwesomeCookieName2"
      style={{ background: "#1e1e1e", fontFamily: "BentonSansCondMed" }}
      buttonStyle={{ background: "#f93843", color: "#fff", fontSize: "13px" }}
      expires={150}
      >
        We use cookies and other tracking technologies to improve your browsing experience on our website and to understand where our visitors are coming from.
      </CookieConsent>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    strapiAction {
      headline
      copy
      LegalText
    }
    strapiIntro {
      description
      headline
      videoID
    }
    strapiInvest {
      headline
      copy
      investItem {
        id
        link
        image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    strapiReform {
      headline
      copy
      actionItem {
        copy
        headline
        id
        image {
          localFile {
            publicURL
          }
          alternativeText
        }
      }
    }
    strapiSocialShare {
      headline
      tweetMessage
      copy
    }
    allStrapiRecipients(sort: { fields: order }) {
      edges {
        node {
          name
          twitterHandle
        }
      }
    }
    allStrapiTweetMessages {
      edges {
        node {
          stat_zero
          under_biden
          past_seven_days
          social_share_recipients {
            name
            twitterHandle
          }
          message
          yesterday
        }
      }
    }

    allOverallStats {
      nodes {
        data_last_updated_at
        yesterday {
          stat_name
          display_stat_name
          stat_number
        }
        past_seven_days {
          stat_name
          display_stat_name
          stat_number
        }
        under_biden {
          stat_name
          display_stat_name
          stat_number
        }
        latest_shootings {
          state_name
          stat_number
        }
      }
    }

    strapiLegal {
      legal_copy
    }

    strapiMethodology {
      methodologyText
    }


    allDataJson(filter: { title: { eq: "overall stats" } }) {
      edges {
        node {
          yesterday {
            stat_name
            stat_number
          }
          past_seven_days {
            stat_name
            stat_number
          }
          under_biden {
            stat_name
            stat_number
          }
          latest_shootings {
            state_name
            stat_number
          }
        }
      }
    }
  }
`;
