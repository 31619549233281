import React, { useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
// swiper bundle styles
import "swiper/css/bundle";
// swiper core styles
import "swiper/css";
// modules styles
import "swiper/css/navigation";
import "swiper/css/pagination";
import StatShareGraphic from "./statShareGraphic";
import makeHandle from "../helpers/makeHandle"
import $ from 'jquery';

const StatsGallery = ({ stats, handleStatSelection }) => {
  const statsYesterday = stats.yesterday.map(stat =>
    Object.assign(stat, { stat_category: "yesterday" })
  );
  const statsPastSevenDays = stats.past_seven_days.map(stat =>
    Object.assign(stat, { stat_category: "past_seven_days" })
  );
  const statsUnderBiden = stats.under_biden.map(stat =>
    Object.assign(stat, { stat_category: "under_biden" })
  );

  const statsSlides = [
    ...statsUnderBiden,
    ...statsPastSevenDays,
    ...statsYesterday
  ].filter(stat => stat.stat_number > 0);

  const onSlideChange = useCallback((data) => {
    var activeIndex = data.activeIndex
    var activeSlide = statsSlides[activeIndex - 1]
    var swiper = $(".swiper-lazy-preloader")
    swiper.show()
    fetch(`https://api.shockmarket.org/screenshot/twitter-cards/${makeHandle(activeSlide.stat_category)}/${makeHandle(activeSlide.stat_name)}`)
      .then((response) => response.json())
      .then( (data) => {
        const img = $(".swiper-slide-active img");
        const src = data.url + "?timestamp=" + new Date().getTime()
        swiper.hide()
        $(img).attr("src", src);
        console.log(src)
      })
    })

  return (
    <Swiper
      className="share-gallery"
      modules={[Pagination, Navigation]}
      loop
      navigation={{ clickable: true }}
      pagination={{ clickable: true, dynamicBullets: true }}
      onSlideChange={onSlideChange}
    >
      {statsSlides.map((stat, i) =>
        <SwiperSlide key={i}>
          <StatShareGraphic category={stat.stat_category} stat={stat} dateInfo={stats.data_last_updated_at} lazy={true} />
          <div style={{ display: 'none'} } className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        </SwiperSlide>
      )}
    </Swiper>
  );
};

export default StatsGallery;
