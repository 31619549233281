import React from "react";
import { useState } from "react";
import getRandomInt from "../helpers/getRandomInt";
import StatsTabs from "./statsTabs"
import StatsGallery from "./statsGallery";
import ShareTweet from "./shareTweet";
import ReactMarkdown from "react-markdown";
import { Dialog } from "@reach/dialog";
import ShareOverlay from "./shareOverlay";
import useToggleState from "../hooks/useToggleState";
import StatsAccordian from "./statsAccordian";
import makeHandle from "../helpers/makeHandle"

const StatsContainer = ({
  containerType,
  headline,
  socialShareCopy,
  lastUpdated,
  stats,
  recipients,
  tweetMessages
}) => {

  // set active states
  const [activeStat, setActiveStat] = useState(stats.yesterday[0])
  const [activeCategory, setActiveCategory] = useState('yesterday')
  const [activeRecipient, setActiveRecipient] = useState(recipients[0].node);
  const [overlayOpen, toggleOverlay] = useToggleState(false);

  // state change handlers
  const handleRecipientChange = recipient => setActiveRecipient(recipient);

  const handleStatSelection = (stat, category) => {
    setActiveStat(stat)
    setActiveCategory(category)
  }

  const handleClose = () => {
    toggleOverlay()
    setActiveRecipient(recipients[0].node) // reset active recipient to first in select menu
  };

  const formattedStat = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const replaceVariables = message => message.replace('[STAT NUMBER]', formattedStat(activeStat.stat_number)).replace('[STAT CATEGORY]', activeStat.stat_name)


  const filterMessages = messages => {
    return messages
    .filter(message => message.node[activeCategory])
    .filter(message => message.node.social_share_recipients.some(recipient => recipient.name === activeRecipient.name))
    .map(message => ({
      message: replaceVariables(message.node.message),
      social_share_recipients: message.node.social_share_recipients,
      subject: message.node.subject ? replaceVariables(message.node.subject) : ''
      }
    ))
  }

  const filteredMessages = filterMessages(tweetMessages);
  const randomInt = getRandomInt(0, filteredMessages.length - 1);
  const defaultMessage = filteredMessages[randomInt].message;

  return (
    <>
      {containerType === 'tab-list' && (
        <>
          <StatsTabs
            handleStatSelection={handleStatSelection}
            stats={stats}
            toggleOverlay={toggleOverlay}
            lastUpdated={lastUpdated}
          />
          <StatsAccordian
              stats={stats}
              activeCategory={activeCategory}
              handleStatSelection={handleStatSelection}
              toggleOverlay={toggleOverlay}
              lastUpdated={lastUpdated}
          />
          <p className="stats__date">last updated {lastUpdated.split(' ')[0].replaceAll('/', '.')}</p>

          <Dialog isOpen={overlayOpen} onDismiss={handleClose} aria-label="overlay">
            <ShareOverlay
              activeCategory={activeCategory}
              activeRecipient={activeRecipient}
              activeStat={activeStat}
              handleRecipientChange={handleRecipientChange}
              handleClose={handleClose}
              overlayOpen={overlayOpen}
              recipients={recipients}
              defaultMessage={defaultMessage}
              messages={filteredMessages}
              lastUpdated={lastUpdated}
            />
          </Dialog>
        </>
      )}

      {containerType === 'gallery' && (
        <div className="social__wrap">
          <div className="social__copy">
            <h2 className="section-header">{headline}</h2>
            <ReactMarkdown children={socialShareCopy} className="section-intro" />
          </div>
          <StatsGallery
            stats={stats}
            handleStatSelection={handleStatSelection}
          />
          <div className="social__wrap">

            <ShareTweet
              key={getRandomInt(0, 1000)}
              handleRecipientChange={handleRecipientChange}
              handleStatSelection={handleStatSelection}
              activeRecipient={activeRecipient}
              activeCategory={activeCategory}
              activeStat={activeStat}
              recipients={recipients}
              defaultMessage={defaultMessage}
              messages={filteredMessages}
              stats={stats}
              lastUpdated={lastUpdated}
            />
          </div>
        </div>
      )}
    </>

  );
};

export default StatsContainer;
