import React from "react"
import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TabPanelContent from "./tabPanelContent";



const StatsTabs = ({ 
  handleStatSelection,
  stats, 
  toggleOverlay
 }) => {

  const [yesterdayCounted, setYesterdayCounted] = useState(false);
  const [pastSevenDaysCounted, setPastSevenDaysCounted] = useState(false);
  const [underBidenCounted, setUnderBidenCounted] = useState(false);
  // won't work if don't pass in index even though not used
  // used to make sure countup only happens on first occurence  
  const handleTabsCounted = (index, lastIndex) => {
    setYesterdayCounted(true);
    if (lastIndex === 1) setPastSevenDaysCounted(true);
    if (lastIndex === 2) setUnderBidenCounted(true);
  }

  return (
    <>
      <Tabs className="stats__share-table" onSelect={(index, lastIndex) => handleTabsCounted(index, lastIndex)}>
        <TabList className="stats__tabs">
          <Tab className="stats__tab">Since Biden&rsquo;s Inauguration</Tab>
          <Tab className="stats__tab">past 7 days</Tab>
          <Tab className="stats__tab">yesterday&rsquo;s losses</Tab>
        </TabList>

        <TabPanel className="share__tab">
          <TabPanelContent
            counted={underBidenCounted} 
            category="under_biden" 
            stats={stats.under_biden}
            handleStatSelection={handleStatSelection}
            toggleOverlay={toggleOverlay}
          />
        </TabPanel>

        <TabPanel className="share__tab">
          <TabPanelContent
            counted={pastSevenDaysCounted}
            category="past_seven_days"
            stats={stats.past_seven_days}
            handleStatSelection={handleStatSelection}
            toggleOverlay={toggleOverlay}
          />
        </TabPanel>

        <TabPanel className="share__tab"> 
          <TabPanelContent
            counted={yesterdayCounted}
            category="yesterday"
            stats={stats.yesterday}
            handleStatSelection={handleStatSelection}
            toggleOverlay={toggleOverlay}
          />
        </TabPanel>


      </Tabs>
    </>
  )
}

export default StatsTabs