import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll";
import Triangle from "./../images/svg/triangle.inline.svg"

const ToTop = () => {
  return (
    <div 
      id="to-top" 
      className="to-top"
      role="link"
      tabIndex={-1} 
      onClick={() => scrollTo("#___gatsby")}
      onKeyPress={() => scrollTo("#___gatsby")}
    >
      <div className="arrows">
        <div className="arrow-A">
          <Triangle />
        </div>
        <div className="arrow-B">
          <Triangle />
        </div>
        <div className="arrow-C">
          <Triangle />
        </div>
        <p className="toTop_text">TOP</p>
      </div>
    </div>
  )
}

export default ToTop;