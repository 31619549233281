import React from "react";
import ReactMarkdown from "react-markdown";

const Methodology = ({ copy }) => {
  return (
    <section id="methodology" className="methodology-wrapper">
      <ReactMarkdown linkTarget="_blank" children={copy} className="methodology" />
    </section>
  );
};

export default Methodology;