import React from "react";
import ReactMarkdown from "react-markdown";

const Legal = ({ copy }) => {
  return (
    <section className="container">
      <ReactMarkdown linkTarget="_blank" children={copy} className="legal" />
    </section>
  );
};

export default Legal;