import React from "react";
import { useRef, useState } from "react"
import TabPanelContent from "./tabPanelContent";

const StatsAccordian = ({ 
  stats,
  activeCategory,
  handleStatSelection,
  toggleOverlay
}) => {
  const accordianItem2 = useRef();
  const accordianItem3 = useRef();

  const [openPanel, setOpenPanel] = useState('yesterday');


  return (
    <div className="stats__share-table stats__share-table--accordian">
      <div className="stats__accordian-item">
        <button 
          className={`stats__tab stats__tab--accordian ${openPanel === 'yesterday' ? 'stats__tab--active' : ''}`}
          onClick={() => setOpenPanel('yesterday')}
          onKeyPress={() => setOpenPanel('yesterday')}
          tabIndex="-1"
        >
          Since Biden&rsquo;s Inauguration
        </button>
        <div
          className="stats__list--accordian" 
          style={
            openPanel === 'yesterday'
              ? { height: "auto"}
              : { height: "0px" }
          }
          >
          <TabPanelContent
            counted={false}
            category="under_biden"
            stats={stats.under_biden}
            handleStatSelection={handleStatSelection}
            toggleOverlay={toggleOverlay}
          />
        </div>
      </div>
      <div className="stats__accordian-item">
        <button
          className={`stats__tab stats__tab--accordian ${openPanel === 'past_seven_days' ? 'stats__tab--active' : ''}`} 
          onClick={() => setOpenPanel('past_seven_days')}
          onKeyPress={() => setOpenPanel('past_seven_days')}
          tabIndex="-1"
        >
          past 7 days
        </button>
        <div
          ref={accordianItem2}  
          className="stats__list--accordian"
          style={
            openPanel === 'past_seven_days'
              ? { height: accordianItem2.current.scrollHeight }
              : { height: "0px" }
          }
        >
          <TabPanelContent
            counted={false}
            category="past_seven_days"
            stats={stats.past_seven_days}
            handleStatSelection={handleStatSelection}
            toggleOverlay={toggleOverlay}
          />
        </div>
      </div>
      <div className="stats__accordian-item">
        <button 
          className={`stats__tab stats__tab--accordian ${openPanel === 'under_biden' ? 'stats__tab--active' : ''}`}
          onClick={() => setOpenPanel('under_biden')}
          onKeyPress={() => setOpenPanel('under_biden')}
          tabIndex="-1"
        >
          yesterday's losses
        </button>
        <div 
          ref={accordianItem3} 
          className="stats__list--accordian"
          style={
            openPanel === 'under_biden'
              ? { height: accordianItem3.current.scrollHeight }
              : { height: "0px" }
          }
          >
          <TabPanelContent
            counted={false}
            category="yesterday"
            stats={stats.yesterday}
            handleStatSelection={handleStatSelection}
            toggleOverlay={toggleOverlay}
          />
        </div>
      </div>
    </div>
  )
}

export default StatsAccordian;