import React from "react";
import ReactMarkdown from "react-markdown";
import Slide from "react-reveal/Slide"
const Reform = ({ headline, copy, actionItems }) => {

  return (
    <section id="reform" className="reform">
      <div id="overlayImage"></div>
      <div className="container">
        <h2 className="section-header">
          {headline}
        </h2>
        <ReactMarkdown children={copy} className="section-intro" />

        <div className="reform__items">
          {actionItems.map((item, i) =>
            <Slide bottom key={i}>
              <div className="reform__item">
                <div className="reform__icon">
                <img src={item.image.localFile.publicURL} alt={item.image.alternativeText}/>
                </div>
                
                <div className="reform__item-copy-wrap">
                <h5 className="reform__item-header">
                  {item.headline}
                </h5>
                <p className="reform__item-copy">
                  {item.copy}
                </p>
                </div>

              </div>
            </Slide >
          )}
        </div>
      </div>
    </section>
  );
};

export default Reform;
