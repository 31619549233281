import React from "react";
import { useRef, useEffect } from "react";
import StatShareGraphic from "./statShareGraphic";
import ShareTweet from "./shareTweet";
import CloseBtn from "./closeBtn";
import getRandomInt from "../helpers/getRandomInt";

const ShareOverlay = ({
  activeRecipient,
  activeCategory,
  activeStat,
  handleRecipientChange,
  handleClose,
  overlayOpen,
  recipients,
  defaultMessage,
  messages,
  lastUpdated
}) => {
  const overlayContentRef = useRef();

  useEffect(
    () => {
      const checkIfClickedOutside = e => {
        if (overlayContentRef.current && !overlayContentRef.current.contains(e.target)) {
          handleClose();
        }
      };

      document.addEventListener("mousedown", checkIfClickedOutside);

      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    },
    [overlayOpen]
  );

  return (
    <div className="share-overlay">
      <div className="close__wrap close__wrap--share">
        <CloseBtn handleClose={handleClose}/>
      </div>

      <div className="share-overlay__content" ref={overlayContentRef}>
        <StatShareGraphic category={activeCategory} stat={activeStat}  dateInfo={lastUpdated} lazy={false} />
        <ShareTweet
          key={getRandomInt(0, 1000)}
          activeStat={activeStat}
          activeCategory={activeCategory}
          activeRecipient={activeRecipient}
          recipients={recipients}
          defaultMessage={defaultMessage}
          messages={messages}
          handleRecipientChange={handleRecipientChange}
          lastUpdated={lastUpdated}
        />
      </div>
    </div>
  );
};

export default ShareOverlay;
