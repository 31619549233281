import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const InvestItem = ({ link, logo }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      aria-label="Donate Now"
      className="invest__item"
    >
      <div className="invest__logo">
        <GatsbyImage image={logo} alt="alt-text" />
      </div>

    </a>
  );
};

export default InvestItem;
