import React from "react";
import ReactMarkdown from "react-markdown";
import InvestItem from "./investItem";
import Plus from "./../images/svg/plus.inline.svg";
// import Frame from "./../images/svg/watch-frame.inline.svg";

const Invest = ({ headline, copy, items }) => {
  return (
    <section id="invest" className="invest gradient-bkd">
      <div className="container">
        <h2 className="section-header">
          {headline}
        </h2>
        <ReactMarkdown children={copy} className="section-intro" />
        <div className="invest__logos">
          
            <InvestItem
              link={items[0].link}
              logo={items[0].image.localFile.childImageSharp.gatsbyImageData}
            />

            <div className="invest__plus">  
              <Plus />
            </div>

            <InvestItem
              link={items[1].link}
              logo={items[1].image.localFile.childImageSharp.gatsbyImageData}
            />

            <div className="invest__plus">  
              <Plus />
            </div>

            <InvestItem
              link={items[2].link}
              logo={items[2].image.localFile.childImageSharp.gatsbyImageData}
            /> 
          
        </div>

        <div className="btn donate"><a href="https://secure.actblue.com/donate/shockmarket" rel="noreferrer" target="_blank">Donate Now</a></div>
      </div>
    </section>
  );
};

export default Invest;
