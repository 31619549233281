import React from "react";
const Iframe = () => {
  return (
    <section id="iframe-Container">
      <h2 className="section-header">Stay Connected for Updates</h2>
      <iframe src="/static/iframe.html" scrolling="no" style={{border:"none", maxWidth: "910px", width: "100%", overflow: "hidden"}}  title="shock market form"></iframe>
    </section>
  );
};

export default Iframe;
