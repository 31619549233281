const year = (d => new Date(d.setDate(d.getDate() - 1)))(new Date())
.toISOString()
.split("T")[0]
.split("-")[0]
.slice(2)

const yesterdayFormatted = (d => new Date(d.setDate(d.getDate() - 1)))(new Date())
.toISOString()
.split("T")[0]
.split("-")
.slice(1, 3)
.join(".").concat(`.${year}`);

export default yesterdayFormatted